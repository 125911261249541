<template>
  <v-layout fill-height align-center>
    <v-row>
      <v-col cols="10" md="8" lg="5" :class="'offset-1 offset-md-3 offset-lg-' + this.layoutDirection">
        <v-card class="elevation-12 login-card content-scroll create-card" light>
          <v-card-title class="primary--text justify-center">{{ projectTitle }}</v-card-title>
          <v-card-text>
            <FormUser
              ref="formUser"
              @control-overlay="
                (flag) => {
                  $emit('control-overlay', flag);
                }
              "
              @success="redirectToLogin"
              @error="$emit('control-overlay', false)"
              showTerms
            ></FormUser>
          </v-card-text>
          <v-card-actions>
            <v-btn :to="{ name: 'Login' }" color="primary" text large exact>{{ $vuetify.lang.t("$vuetify.auth.backToLogin") }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="loadingSubmitButton" color="primary" depressed right large @click="createUser">
              {{ $vuetify.lang.t("$vuetify.buttons.send") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<style lang="scss" scoped>
.create-card {
  max-height: calc(100vh - 90px);
}
</style>

<script>
import FormUser from "@/components/forms/User";
import parameterConfigurationApi from "@/api/parameterConfiguration";
import { enumContentConfiguration } from "@/enums/configurationKeys";
import parameterConfiguration from "@/api/parameterConfiguration";

export default {
  name: "CreateUser",
  components: { FormUser },
  metaInfo: {
    title: "Criar Usuário",
  },
  data: () => ({
    loadingSubmitButton: false,
    projectTitle: "",
    layoutDirection: "8",
  }),
  async created() {
    this.getLayoutDirection();
    await parameterConfigurationApi
      .get()
      .then(async (configurationItems) => {
        // Set new colors configuration
        await Promise.all(
          configurationItems.map(async (element) => {
            if (element.code === enumContentConfiguration.VUEAPPTITLE && element.value) this.projectTitle = element.value;
          })
        );
      })
      .catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.layoutConfiguration.errors.get"),
          text: error.message,
        });
      });
  },
  methods: {
    // Login function
    createUser(e) {
      // Prevent default action
      e.preventDefault();
      // Show Overlay
      this.$emit("control-overlay", true);
      // validate form
      this.$refs.formUser.submitForm();
    },
    redirectToLogin() {
      // Redirect to Index
      this.$router.push({ name: "Login" }, () => {
        this.$notify({
          group: "application",
          type: "success",
          title: this.$vuetify.lang.t("$vuetify.user.notifications.success.createUser.title"),
          text: this.$vuetify.lang.t("$vuetify.user.notifications.success.createUser.message"),
        });
      });
      // Hide Overlay
      this.$emit("control-overlay", false);
    },
    getLayoutDirection() {
      parameterConfiguration.getLayoutDirection().then((response) => {
        if (response.toUpperCase() == "LEFT") this.layoutDirection = 1;
        if (response.toUpperCase() == "CENTER") this.layoutDirection = 4;
        if (response.toUpperCase() == "RIGHT") this.layoutDirection = 6;
      });
    },
  },
};
</script>
