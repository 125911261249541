<template>
  <v-app>
    <notifications group="application" class="vue-custom-notification" position="top right" :duration="5000" />
    <AppBar :key="$route.name" v-if="themeConfigured" />
    <router-view v-if="themeConfigured"></router-view>
  </v-app>
</template>

<script>
import AppBar from "@/components/layout/AppBar";
import layoutConfigurationApi from "@/api/layoutConfiguration";
import parameterConfigurationApi from "@/api/parameterConfiguration";
import { enumLayoutConfiguration, enumContentConfiguration } from "@/enums/configurationKeys";
export default {
  name: "App",
  components: { AppBar },
  data() {
    return {
      metaTitle: "",
      themeConfigured: false,
    };
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.metaTitle,
      // all titles will be injected into this template
      titleTemplate: "%s | " + this.metaTitle,
    };
  },
  async created() {
    // Check if already customized
    await parameterConfigurationApi.get().then((configurationItems) => {
      configurationItems.forEach((element) => {
        if (element.code === enumContentConfiguration.VUEAPPTITLECLIENT) this.businessNameLabel = element.value;
        if (element.code === enumContentConfiguration.VUEAPPTITLE && element.value) this.metaTitle = element.value;
      });
    });
    if (!this.themeConfigured) {
      // Get Configuration Colors
      await layoutConfigurationApi
        .get()
        .then(async (configurationItems) => {
          // Set new colors configuration
          await Promise.all(
            configurationItems.map(async (element) => {
              if (element.code === enumLayoutConfiguration.PRIMARYCOLOR && element.value) this.$vuetify.theme.currentTheme.primary = element.value;
              if (element.code === enumLayoutConfiguration.SECONDARYCOLOR && element.value)
                this.$vuetify.theme.currentTheme.secondary = element.value;
              // if (element.code === enumContentConfiguration.VUEAPPTITLE && element.value) this.metaTitle = element.value;
            })
          );
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.layoutConfiguration.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          this.themeConfigured = true;
        });
    }
  },
};
</script>
