<template>
  <v-data-table
    :headers="tableHeaders"
    :items="listItems"
    :items-per-page="15"
    :server-items-length="totalRecords"
    :loading="loadingTable"
    :options="{ sortBy: ['description'], sortDesc: [false] }"
    :footer-props="{ itemsPerPageOptions: [5, 10, 15, 25, 50] }"
    class="stripped-datatable elevation-1"
    @update:options="getTableItems"
  >
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :to="{ name: 'RegisterCategories', params: { id: item.id } }" v-bind="attrs" v-on="on" small icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ $vuetify.lang.t("$vuetify.categories.editCategory") }}</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>
<script>
import categoryApi from "@/api/category";
export default {
  name: "CategoryTable",
  data: () => ({
    listItems: [],
    totalRecords: null,
    loadingTable: false,
    tableHeaders: false,
    disableButton: false,
  }),
  computed: {},
  created() {
    // Get Table Headers
    this.tableHeaders = this.getTableHeaders();
  },
  methods: {
    getTableHeaders() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.categories.fields.category"),
          align: "start",
          value: "description",
        },
        { text: this.$vuetify.lang.t("$vuetify.labels.actions"), value: "actions", width: 100, sortable: false },
      ];
    },
    getTableItems(options = {}) {
      // Loading Table
      this.loadingTable = true;
      // Create pagination parameters
      let params = {};
      if (options.page) params.page = options.page - 1;
      if (options.itemsPerPage) params.size = options.itemsPerPage;
      if (options.sortBy && options.sortBy[0]) params.sort = options.sortBy[0] + "," + (options.sortDesc[0] ? "desc" : "asc");
      // Get Users
      // Get Users
      categoryApi
        .getList(params)
        .then((response) => {
          this.listItems = response.data;
          this.totalRecords = response.totalRecords;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.categories.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Loading Table
          this.loadingTable = false;
        });
    },
    toggleCategory(categoryObj, active = 0) {
      if (!categoryObj) return;
      if (!categoryObj.id) return;
      // Loading Table
      this.disableButton = true;
      // Get Users
      categoryApi
        .update({
          ...categoryObj,
          active: active,
        })
        .then((response) => {
          let updateIndex = this.listItems.findIndex((element) => response.id === element.id);
          this.listItems[updateIndex].active = response.active;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.categories.errors.update"),
            text: error.message,
          });
        })
        .finally(() => {
          // Loading Table
          this.disableButton = false;
        });
    },
  },
};
</script>
