<template>
  <v-main>
    <v-container fluid fill-height class="pa-0">
      <v-img height="100%" :src="bannerPath">
        <v-overlay class="auth-overlay" absolute>
          <router-view :key="routeKey" @control-overlay="controlLoadingOverlay"></router-view>
        </v-overlay>
      </v-img>
    </v-container>
    <VAppLoadingOverlay v-model="showOverlay" />
  </v-main>
</template>

<script>
export default {
  name: "AuthTemplate",
  data: () => ({
    showOverlay: false,
  }),
  computed: {
    routeKey: function () {
      // `this` aponta para a instância Vue da variável `vm`
      return this.$route.name + (this.$route.params.id ? this.$route.params.id : "");
    },
    bannerPath: () => {
      return process.env.NODE_ENV == "development" ? process.env.VUE_APP_BANNER_PATH : location.origin + "/api/portal-photos/banner.png";
    },
  },
  methods: {
    controlLoadingOverlay(flag) {
      this.showOverlay = flag;
    },
  },
};
</script>
