<template>
  <VAppLoadingOverlay v-model="showOverlay" />
</template>
<script>
export default {
  name: "OauthPkce",
  data: () => ({
    showOverlay: true,
    oauthObj: {
      code: "",
      codeVerifier: "",
    },
  }),
  created() {
    this.oauthObj.code = this.$route.query.code;
    this.oauthObj.codeVerifier = localStorage.getItem("oauthpkce");
    window.location.href = `${
      process.env.NODE_ENV == "development" ? process.env.VUE_APP_ROOT_API : location.origin + "/api/v1"
    }/authentication/oauth?code=${this.oauthObj.code}&oauthpkce=${this.oauthObj.codeVerifier}`;
  },
};
</script>
