const enumLayoutConfiguration = {
  PRIMARYCOLOR: "PRIMARY_COLOR",
  SECONDARYCOLOR: "SECONDARY_COLOR",
  LOGOIMG: "LOGO_IMG",
  BANNERIMG: "BANNER_IMG",
};
const enumEmailConfiguration = {
  SMTPSERVER: "SMTP",
  SMTPPORT: "SMTP_PORT",
  SMTPEMAIL: "SMTP_EMAIL",
  SMTPPASS: "SMTP_PASS",
  SMTPFROM: "SMTP_FROM",
  SMTPTLS: "SMTP_START_TLS",
};
const enumContentConfiguration = {
  TERMSCONDITIONS: "TERMS_CONDITION",
  VUEAPPTITLECLIENT: "APP_TITLE_CLIENT",
  VUEAPPTITLE: "APP_TITLE",
};
const enumAPIConfiguration = {
  PATHSERVICELECOMMODEL: "PATH_SERVICE_LECOM_MODEL",
  PATHSERVICELECOMAUTHENTICATION: "PATH_SERVICE_LECOM_AUTHENTICATION",
  PATHSERVICELECOMOPENPROCESS: "PATH_SERVICE_LECOM_OPEN_PROCESS",
  PATHSERVICELECOMPROCESSINSTANCES: "PATH_SERVICE_LECOM_PROCESS_INSTANCES",
  PATHSERVICELECOMFORMAPP: "PATH_SERVICE_LECOM_FORM_APP",
  SERVICELECOMAUTHENTICATIONADMINLOGIN: "SERVICE_LECOM_AUTHENTICATION_ADMIN_LOGIN",
  SERVICELECOMAUTHENTICATIONADMINPASSWORD: "SERVICE_LECOM_AUTHENTICATION_ADMIN_PASSWORD",
  SERVICELECOMUSERPUBLICLOGIN: "SERVICE_LECOM_USER_PUBLIC_LOGIN",
  SERVICELECOMUSERPUBLICPASS: "SERVICE_LECOM_USER_PUBLIC_PASS",
  SERVICELECOMUSERPUBLICID: "SERVICE_LECOM_USER_PUBLIC_ID",
  URLSERVICELECOMBPM: "URL_SERVICE_LECOM_BPM",
};
const enumParameterConfiguration = {
  AUTHENTICATIONTYPE: "AUTHENTICATION_TYPE",
  LOGINPARAMETERURL: "LOGIN_PARAMETER_URL",
  LOGINPARAMETERAPIURL: "LOGIN_PARAMETER_API_URL",
  LOGINPARAMETERAPIUSER: "LOGIN_PARAMETER_API_USER",
  LOGINPARAMETERAPIPASSWORD: "LOGIN_PARAMETER_API_PASSWORD",
  OAUTHPKCEURL: "OAUTHPKCE_URL",
  OAUTHPKCECLIENTID: "OAUTHPKCE_CLIENT_ID",
  OAUTHPKCECLIENTSECRET: "OAUTHPKCE_CLIENT_SECRET",
  OAUTHPKCEGRANTTYPE: "OAUTHPKCE_GRANT_TYPE",
  URLAMBIENTEFRONT: "URL_AMBIENTE_FRONT",
  APITOKENUSER: "API_TOKEN_USER",
  APITOKENPASSWORD: "API_TOKEN_PASSWORD",
  AUTHLAYOUTDIRECTION: "AUTH_LAYOUT_DIRECTION",
  FAILEDLOGINCOUNT: "FAILED_LOGIN_COUNT",
  APPTITLECLIENT: "APP_TITLE_CLIENT",
  APPTITLE: "APP_TITLE",
};
export { enumLayoutConfiguration, enumEmailConfiguration, enumContentConfiguration, enumAPIConfiguration, enumParameterConfiguration };
