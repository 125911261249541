import HttpClient from "@/services/httpClient.js";
const apiPath = "/parameterConfiguration/";

export default {
  get() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath,
    }).then((response) => response.data);
  },
  getByCode(code) {
    if (!code) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + code,
    }).then((response) => response.data);
  },
  save(code, value) {
    if (!code) return Promise.reject({ message: "error" });
    if (!value) return Promise.reject({ message: "error" });
    let configurationObj = {
      code: code,
      value: value,
    };
    return HttpClient.apiRequest({
      method: "PUT",
      url: apiPath + code,
      data: configurationObj,
    }).then((response) => response.data);
  },
  getLoginParameter(route) {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "loginParameter",
    }).then(async (response) => {
      localStorage.setItem("loginParameter", response.data);
      if (response.data && route != "Validate" && route != "Reset") {
        window.location.href = "/entrar/validar";
      } else if (!response.data && route == "Validate") {
        window.location.href = "/entrar";
      }
      if (!response.data) {
        localStorage.removeItem("cpfCnpj");
      }
    });
  },

  getOauthpkceParameter() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "oauthpkceParameter",
    }).then(async (response) => {
      localStorage.setItem("oauthpkceParameter", response.data);
    });
  },

  getOauthpkceURL() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "oauthpkceUrl",
    }).then((response) => response.data);
  },

  getOauthpkceClientId() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "oauthpkceclientid",
    }).then((response) => response.data);
  },

  getLayoutDirection() {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "authLayoutDirection",
    }).then((response) => response.data);
  },
};
