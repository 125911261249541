import HttpClient from "@/services/httpClient.js";
const apiPath = "/user/";
export default {
  get(params = null) {
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath,
      params: params,
    }).then((response) => response.data);
  },
  getById(userId) {
    if (!userId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + userId,
    }).then((response) => response.data);
  },
  create(userObj) {
    if (!userObj) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "POST",
      url: apiPath,
      data: userObj,
    }).then((response) => response.data);
  },
  update(userObj) {
    if (!userObj) return Promise.reject({ message: "error" });
    if (!userObj.id) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "PUT",
      url: apiPath + userObj.id,
      data: userObj,
    }).then((response) => response.data);
  },
  delete(userId) {
    if (!userId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "DELETE",
      url: apiPath + userId,
    }).then((response) => response.data);
  },
  getPermissions(userId) {
    if (!userId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "permission/" + userId,
    }).then((response) => response.data);
  },
  changeCredentials(token, password) {
    if (!token) return Promise.reject({ message: "error" });
    if (!password) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "POST",
      url: apiPath + "validationForgot/" + token,
      data: {
        password: password,
      },
    }).then((response) => response.data);
  },
  validateToken(token) {
    return HttpClient.apiRequest({
      method: "POST",
      url: apiPath + "validation/" + token,
    }).then((response) => response.data);
  },
  getPermissionByUser(userId) {
    if (!userId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "permissionByUser/" + userId,
    }).then((response) => response.data);
  },
  approveUser(userId) {
    if (!userId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "PUT",
      url: apiPath + "approve/" + userId,
    }).then((response) => response.data);
  },
  saveUserPermissions(userId, permissionsData) {
    if (!userId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "PUT",
      url: apiPath + "permission/" + userId,
      data: permissionsData,
    }).then((response) => response.data);
  },

  getPermissionByGroup(groupId) {
    if (!groupId) return Promise.reject({ message: "error" });
    return HttpClient.apiRequest({
      method: "GET",
      url: apiPath + "permissionByGroup/" + groupId,
    }).then((response) => response.data);
  },
};
