<template>
  <v-main>
    <VAppLoadingOverlay v-model="showOverlay" />
  </v-main>
</template>

<script>
import tokenService from "@/services/token";

export default {
  name: "ValidateUser",
  data: () => ({
    showOverlay: true,
  }),
  created() {
    tokenService.saveToken(this.$route.params.token);
    if (this.$route.params.idtoken) localStorage.setItem("id_token", this.$route.params.idtoken);
    this.$router.push({ name: "Login" });
  },
};
</script>
