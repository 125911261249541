<template>
  <v-layout fill-height align-center>
    <v-row>
      <v-col cols="10" md="6" lg="3" :class="'offset-1 offset-md-3 offset-lg-' + this.layoutDirection">
        <v-card class="elevation-12 login-card" light>
          <v-card-title class="primary--text justify-center">{{ projectTitle }}</v-card-title>
          <ValidationObserver ref="observerLogin">
            <v-form ref="form" @submit="handleLogin">
              <v-card-text>
                <ValidationProvider :name="$vuetify.lang.t('$vuetify.auth.email')" rules="required|email" v-slot="{ errors }">
                  <VAppTextField
                    v-model="authObj.email"
                    name="email"
                    prepend-inner-icon="mdi-account"
                    :label="$vuetify.lang.t('$vuetify.auth.email')"
                    :error-messages="errors"
                  ></VAppTextField>
                </ValidationProvider>
                <ValidationProvider :name="$vuetify.lang.t('$vuetify.auth.password')" rules="required" v-slot="{ errors }">
                  <VAppTextField
                    v-model="authObj.password"
                    type="password"
                    name="password"
                    prepend-inner-icon="mdi-lock"
                    :label="$vuetify.lang.t('$vuetify.auth.password')"
                    :error-messages="errors"
                  ></VAppTextField>
                </ValidationProvider>
              </v-card-text>
              <v-card-actions>
                <v-btn :to="{ name: 'Forgot' }" color="primary" text large>
                  {{ $vuetify.lang.t("$vuetify.auth.forgot") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit" depressed right large>
                  {{ $vuetify.lang.t("$vuetify.buttons.login") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </ValidationObserver>
          <v-card-text class="pa-0" v-if="loginParameter == 'false' || loginParameter == null">
            <TextDivider :label="$vuetify.lang.t('$vuetify.labels.or')"></TextDivider>
          </v-card-text>
          <v-card-actions v-if="loginParameter == 'false' || loginParameter == null">
            <v-btn :to="{ name: 'CreateUser' }" color="primary" width="100%" large text exact>
              <v-icon>mdi-account-plus</v-icon>{{ $vuetify.lang.t("$vuetify.auth.createUser") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import authenticationApi from "@/api/authentication";
import TextDivider from "@/components/layout/TextDivider";
import parameterConfiguration from "@/api/parameterConfiguration";
import parameterConfigurationApi from "@/api/parameterConfiguration";
import { enumContentConfiguration } from "@/enums/configurationKeys";

export default {
  name: "Login",
  components: { TextDivider },
  metaInfo: {
    title: "Entrar",
  },
  data: () => ({
    authObj: {
      email: "",
      password: "",
      remember: false,
    },
    oauthObj: {
      codeVerifier: "",
      codeChallenge: "",
    },
    loginParameter: null,
    loginOauthURL: "",
    loginOauthClientID: "",
    projectTitle: "",
    layoutDirection: "8",
  }),
  async created() {
    this.getLayoutDirection();
    await parameterConfigurationApi
      .get()
      .then(async (configurationItems) => {
        // Set new colors configuration
        await Promise.all(
          configurationItems.map(async (element) => {
            if (element.code === enumContentConfiguration.VUEAPPTITLE && element.value) this.projectTitle = element.value;
          })
        );
      })
      .catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.layoutConfiguration.errors.get"),
          text: error.message,
        });
      });

    parameterConfiguration.getOauthpkceURL().then((response) => {
      this.loginOauthURL = response;
    });

    parameterConfiguration.getOauthpkceClientId().then((response) => {
      this.loginOauthClientID = response;
    });

    this.returnOauthpkceParameter();
    this.loginParameter = localStorage.getItem("loginParameter");
  },
  methods: {
    async returnOauthpkceParameter() {
      await parameterConfiguration.getOauthpkceParameter();
      var oauthpkce = localStorage.getItem("oauthpkceParameter");
      if (oauthpkce == "true") {
        this.handleLoginOauth();
      }
    },
    // Login function
    handleLogin(e) {
      // Prevent default action
      e.preventDefault();
      // Show Overlay
      this.$emit("control-overlay", true);
      // validate form
      this.$refs.observerLogin.validate().then((success) => {
        if (success) {
          authenticationApi
            .authenticate(this.authObj)
            .then(() => {
              // Hide Overlay
              this.$emit("control-overlay", false);
              // Redirect User to Dashboard
              this.$router.push({ name: "Dashboard" });
            })
            .catch((error) => {
              this.$notify({
                group: "application",
                type: "error",
                title: this.$vuetify.lang.t("$vuetify.api.authentication.errors.authenticate"),
                text: error.message,
              });
            })
            .finally(() => {
              // Hide Overlay
              this.$emit("control-overlay", false);
            });
        } else {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.auth.notifications.errors.formObserverLogin.title"),
            text: this.$vuetify.lang.t("$vuetify.auth.notifications.errors.formObserverLogin.message"),
          });
          // Hide Overlay
          this.$emit("control-overlay", false);
        }
      });
    },
    handleLoginOauth() {
      // Show Overlay
      this.$emit("control-overlay", true);
      this.oauthObj.codeVerifier = this.generateCodeVerifier();
      localStorage.setItem("oauthpkce", this.oauthObj.codeVerifier);
      this.oauthObj.codeChallenge = this.generateCodeChallenge();

      window.location.href = `${this.loginOauthURL}/connect/authorize?
client_id=${this.loginOauthClientID}&
redirect_uri=${location.origin}/oauthpkce&
response_type=code&
scope=openid+profile+email+offline_access+IdentityServerApi+${this.loginOauthClientID}&
state=UQCZRlSlLSu13jS2&
code_challenge=${this.oauthObj.codeChallenge}&
code_challenge_method=S256`;
    },
    generateCodeVerifier() {
      var text = "";
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
      for (var i = 0; i < 128; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    },
    generateCodeChallenge() {
      var code_challenge = this.CryptoJS.SHA256(this.oauthObj.codeVerifier);
      code_challenge = code_challenge.toString(this.CryptoJS.enc.Base64).replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
      return code_challenge;
    },
    getLayoutDirection() {
      parameterConfiguration.getLayoutDirection().then((response) => {
        if (response.toUpperCase() == "LEFT") this.layoutDirection = 1;
        if (response.toUpperCase() == "CENTER") this.layoutDirection = 5;
        if (response.toUpperCase() == "RIGHT") this.layoutDirection = 8;
      });
    },
  },
};
</script>
