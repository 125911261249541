<template>
  <div class="pa-4 iframe-container">
    <v-row justify="space-between">
      <v-col class="text-h4" cols="12" sm="auto">{{ $vuetify.lang.t("$vuetify.process." + titleLabel) }}{{ lecomTitle }}</v-col>
      <v-col cols="12" sm="auto">
        <v-btn :to="{ name: 'Dashboard' }" color="primary" text large exact>
          {{ $vuetify.lang.t("$vuetify.buttons.backToDashboard") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-skeleton-loader v-if="!lecomFormUrl" type="text@3"></v-skeleton-loader>
    <iframe
      v-else
      name="bpm-iframe"
      id="bpm-iframe"
      class="iframe-element"
      :src="lecomFormUrl"
      frameborder="0"
      width="100%"
      height=""
      allow="encrypted-media"
      scrolling="yes"
    ></iframe>
  </div>
</template>

<script>
import lecomApi from "@/api/lecom";
export default {
  name: "ServiceRegister",
  data: () => ({
    lecomFormUrl: null,
    lecomTitle: "",
  }),
  props: {
    titleLabel: {
      type: String,
      default: "view",
    },
    action: {
      type: String,
      default: "postOpenProcess",
    },
    activeTab: {
      type: String,
      default: null,
    },
  },
  mounted() {
    // Show Overlay
    this.$emit("control-overlay", true);
    // Check if is editing user
    if (this.$route.params.id) {
      // Get Lecom Form
      lecomApi[this.action](this.$route.params.id)
        .then((response) => {
          this.lecomFormUrl = response.url;
          this.lecomTitle = " - " + response.titleForm + " - " + response.processInstanceId;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.lecom.errors." + this.action),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
        });
    } else {
      // Redirect to Index
      this.$router.push({ name: "Dashboard" }, () => {
        // Hide Overlay
        this.$emit("control-overlay", false);
        // Notify Success
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.process.notifications.error.initProcess.title"),
          text: this.$vuetify.lang.t("$vuetify.process.notifications.error.initProcess.message"),
        });
      });
    }
    // Add Iframe Listener
    window.addEventListener("message", this.receiveMessage);
  },
  methods: {
    receiveMessage(event) {
      switch (event.data.event) {
      case "PROCESS_FORM_SUBMIT_SUCCESS":
        if (event.data.data[2] == "aprovar") {
          this.$notify({
            group: "application",
            type: "success",
            title: this.$vuetify.lang.t("$vuetify.process.notifications.accept.title"),
            text: this.$vuetify.lang.t("$vuetify.process.notifications.accept.message"),
          });
        } else if (event.data.data[2] == "cancel") {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.process.notifications.cancel.title"),
            text: this.$vuetify.lang.t("$vuetify.process.notifications.cancel.message"),
          });
        } else if (event.data.data[2] == "rejeitar") {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.process.notifications.reject.title"),
            text: this.$vuetify.lang.t("$vuetify.process.notifications.reject.message"),
          });
        } else if (event.data.data[2] == "finish") {
          this.$notify({
            group: "application",
            type: "success",
            title: this.$vuetify.lang.t("$vuetify.process.notifications.finish.title"),
            text: this.$vuetify.lang.t("$vuetify.process.notifications.finish.message"),
          });
        }

        if (this.activeTab) this.$router.push({ name: "Dashboard", query: { at: this.activeTab } });
        else this.$router.push({ name: "Dashboard" });
        break;
      case "FORM_LOAD_FINISH":
        this.callUrlEventIframe();
        break;
      }
    },
    callUrlEventIframe() {
      setTimeout(() => {
        let iframe = document.getElementById("bpm-iframe");
        iframe.contentWindow.postMessage({ event: window.location.href }, this.lecomFormUrl);
      }, 1000);
    },
  },
  beforeDestroy() {
    // Destroi Iframe Listener
    window.removeEventListener("message", this.receiveMessage);
  },
};
</script>

<style scoped>
.iframe-container {
  height: 100%;
}
.iframe-element {
  width: 100%;
  height: calc(100% - 40px);
  border: 0;
}
iframe {
  width: 100%;
}
</style>
