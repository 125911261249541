<template>
  <div class="pa-4">
    <v-row>
      <v-col class="text-h4" cols="12">{{ $vuetify.lang.t("$vuetify.categories.title") }}</v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="9">
        <v-card>
          <v-card-title class="primary--text justify-center">
            {{ $vuetify.lang.t("$vuetify.categories." + ($route.params.id ? "updateCategory" : "createCategory")) }}
          </v-card-title>
          <v-card-text>
            <FormCategory ref="formCategory" @success="formSuccess" @error="$emit('control-overlay', false)"></FormCategory>
          </v-card-text>
          <v-card-actions>
            <v-btn :to="{ name: 'Administrator', query: { at: 'CATEGORIES' } }" color="primary" text large exact>
              {{ $vuetify.lang.t("$vuetify.user.backToList") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed right large @click="submitForm">
              {{ $vuetify.lang.t("$vuetify.buttons.send") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import categoryApi from "@/api/category";
import FormCategory from "@/components/forms/Category";
export default {
  name: "CategoryRegister",
  components: { FormCategory },
  data: () => ({
    loadingOverlay: false,
  }),
  mounted() {
    // Check if is editing user
    if (this.$route.params.id) {
      // Show Overlay
      this.$emit("control-overlay", true);
      // Get Category
      categoryApi
        .getById(this.$route.params.id)
        .then((response) => {
          // set Category Obj
          this.$refs.formCategory.setCategoryObj(response);
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.categories.errors.getById"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
        });
    }
  },
  methods: {
    // Submit Form function
    submitForm(e) {
      // Prevent default action
      e.preventDefault();
      // Show Overlay
      this.$emit("control-overlay", true);
      // validate form
      this.$refs.formCategory.submitForm();
    },
    formSuccess() {
      // Redirect to Index
      this.$notify({
        group: "application",
        type: "success",
        title: this.$vuetify.lang.t(
          "$vuetify.categories.notifications.success." + (this.$route.params.id ? "updateCategory" : "createCategory") + ".title"
        ),
        text: this.$vuetify.lang.t(
          "$vuetify.categories.notifications.success." + (this.$route.params.id ? "updateCategory" : "createCategory") + ".message"
        ),
      });
      // Hide Overlay
      this.$emit("control-overlay", false);
    },
  },
};
</script>
