import AuthTemplate from "@/views/auth/Template";
import Login from "@/views/auth/Login";
import Forgot from "@/views/auth/Forgot";
import Reset from "@/views/auth/Reset";
import CreateUser from "@/views/auth/CreateUser";
import ValidateUser from "@/views/auth/ValidateUser";
import OauthUser from "@/views/auth/OauthUser";
import DefaultTemplate from "@/views/DefaultTemplate";
import Dashboard from "@/views/Dashboard";
import SiteConfiguration from "@/views/configuration/Configuration";
import Administrator from "@/views/administrator/Administrator";
// import ListUsers from "@/views/users/List";
import RegisterUsers from "@/views/users/Register";
import MyAccount from "@/views/users/MyAccount";
import RegisterServices from "@/views/services/Register";
import RegisterCategories from "@/views/services/RegisterCategories";
import RegisterGroups from "@/views/groups/RegisterGroups";
import RegisterTabs from "@/views/services/RegisterTabs";
import ViewProcess from "@/views/process/View";
import Validate from "@/views/auth/Validate";
import OauthPkce from "@/views/auth/OauthPkce";
import AlternativeLogin from "@/views/auth/AlternativeLogin";

const routes = [
  {
    path: "/u/validarOauth/:token/:idtoken",
    name: "OauthUser",
    component: OauthUser,
    meta: {
      OnlyLoggedOut: true,
    },
  },
  {
    path: "/u/validar/:token",
    name: "ValidateUser",
    component: ValidateUser,
    meta: {
      OnlyLoggedOut: true,
    },
  },
  {
    path: "/oauthpkce",
    name: "OauthPkce",
    component: OauthPkce,
    meta: {
      OnlyLoggedOut: true,
    },
  },
  {
    path: "/entrar",
    component: AuthTemplate,
    meta: {
      OnlyLoggedOut: true,
    },
    children: [
      {
        path: "",
        name: "Login",
        component: Login,
      },
      {
        path: "login-alternativo",
        name: "AlternativeLogin",
        component: AlternativeLogin,
      },
      {
        path: "esqueci",
        name: "Forgot",
        component: Forgot,
      },
      {
        path: "novo-usuario",
        name: "CreateUser",
        component: CreateUser,
      },
      {
        path: "reset/:token",
        name: "Reset",
        component: Reset,
      },
      {
        path: "validar",
        name: "Validate",
        component: Validate,
      },
    ],
  },
  {
    path: "/",
    component: DefaultTemplate,
    meta: {
      OnlyLogged: true,
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
        props: (route) => ({
          activeTab: route.query.at,
        }),
      },
      {
        path: "configuracao",
        name: "Configuration",
        component: SiteConfiguration,
        props: (route) => ({
          activeTab: route.query.at ? route.query.at : null,
        }),
      },
      {
        path: "administrador",
        name: "Administrator",
        component: Administrator,
        props: (route) => ({
          activeTab: route.query.at ? route.query.at : null,
        }),
      },
      // {
      //   path: "usuarios",
      //   name: "ListUsers",
      //   component: ListUsers,
      // },
      {
        path: "usuarios/f/:id?",
        name: "RegisterUsers",
        component: RegisterUsers,
      },
      {
        path: "minha-conta",
        name: "MyAccount",
        component: MyAccount,
      },
      {
        path: "groups/f/:id?",
        name: "RegisterGroups",
        component: RegisterGroups,
      },
      {
        path: "services/f/:id?",
        name: "RegisterServices",
        component: RegisterServices,
      },
      {
        path: "categories/f/:id?",
        name: "RegisterCategories",
        component: RegisterCategories,
      },
      {
        path: "tabs/f/:id",
        name: "RegisterTabs",
        component: RegisterTabs,
      },
      {
        path: "process/v/:id?",
        name: "ViewProcess",
        component: ViewProcess,
        props: (route) => ({
          titleLabel: route.query.l,
          action: route.query.a,
          activeTab: route.query.at,
        }),
      },
    ],
  },
];
export default routes;
