<template>
  <ValidationObserver ref="observerUser">
    <v-row class="my-0">
      <v-col class="py-0" cols="12" md="6">
        <ValidationProvider :name="$vuetify.lang.t('$vuetify.user.fields.name')" rules="required" v-slot="{ errors }">
          <VAppTextField
            v-model="userObj.name"
            name="name"
            prepend-inner-icon="mdi-account"
            :label="$vuetify.lang.t('$vuetify.user.fields.name')"
            :error-messages="errors"
            :disabled="!!userObj.id"
          ></VAppTextField>
        </ValidationProvider>
      </v-col>
      <v-col class="py-0" cols="12" md="6" v-if="!isCnpj || isCnpj == null">
        <ValidationProvider :name="$vuetify.lang.t('$vuetify.user.fields.cpf')" rules="required|cpf" v-slot="{ errors }">
          <VAppTextField
            v-model="userObj.cpf"
            name="cpf"
            prepend-inner-icon="mdi-card-account-details"
            :label="$vuetify.lang.t('$vuetify.user.fields.cpf')"
            :error-messages="errors"
            v-mask="cpfMask"
            :disabled="isCnpj != null"
          ></VAppTextField>
        </ValidationProvider>
      </v-col>
      <v-col class="py-0" cols="12" v-bind:md="isCnpj || isCnpj == null ? '6' : '12'">
        <ValidationProvider :name="$vuetify.lang.t('$vuetify.user.fields.email')" rules="required|email" v-slot="{ errors }">
          <VAppTextField
            v-model="userObj.email"
            name="email"
            prepend-inner-icon="mdi-at"
            :label="$vuetify.lang.t('$vuetify.user.fields.email')"
            :error-messages="errors"
            :disabled="!!userObj.id"
          ></VAppTextField>
        </ValidationProvider>
      </v-col>
      <v-col class="py-0" cols="12" md="6" v-if="isCnpj == null">
        <ValidationProvider :name="$vuetify.lang.t('$vuetify.user.fields.phone')" rules="required" v-slot="{ errors }">
          <VAppTextField
            v-model="userObj.phone"
            name="phone"
            prepend-inner-icon="mdi-phone"
            :label="$vuetify.lang.t('$vuetify.user.fields.phone')"
            :error-messages="errors"
          ></VAppTextField>
        </ValidationProvider>
      </v-col>
      <v-col class="py-0" cols="12" md="6" v-if="isCnpj || isCnpj == null">
        <ValidationProvider :name="'Nome do ' + businessNameLabel" rules="required" v-slot="{ errors }">
          <VAppTextField
            v-model="userObj.businessName"
            name="businessName"
            prepend-inner-icon="mdi-office-building"
            :label="'Nome do ' + businessNameLabel"
            :error-messages="errors"
          ></VAppTextField>
        </ValidationProvider>
      </v-col>
      <v-col class="py-0" cols="12" md="6" v-if="isCnpj || isCnpj == null">
        <ValidationProvider :name="'CNPJ do ' + businessNameLabel" rules="required|cnpj" v-slot="{ errors }">
          <VAppTextField
            v-model="userObj.businessCnpj"
            name="businessCnpj"
            prepend-inner-icon="mdi-office-building"
            :label="'CNPJ do ' + businessNameLabel"
            :error-messages="errors"
            v-mask="cnpjMask"
          ></VAppTextField>
        </ValidationProvider>
      </v-col>
      <v-col class="py-0" cols="12" md="6">
        <ValidationProvider
          :name="$vuetify.lang.t('$vuetify.user.fields.password')"
          :rules="(userObj.id ? '' : 'required|') + 'min:8|password'"
          v-slot="{ errors }"
        >
          <VAppTextField
            v-model="userObj.password"
            type="password"
            name="password"
            prepend-inner-icon="mdi-lock"
            :label="$vuetify.lang.t('$vuetify.user.fields.password')"
            :error-messages="errors"
          ></VAppTextField>
        </ValidationProvider>
      </v-col>
      <v-col class="py-0" cols="12" md="6">
        <ValidationProvider
          :name="$vuetify.lang.t('$vuetify.labels.confirmPassword')"
          :rules="(userObj.id ? '' : 'required|') + 'equal:@' + $vuetify.lang.t('$vuetify.user.fields.password')"
          v-slot="{ errors }"
        >
          <VAppTextField
            v-model="confirmPassword"
            type="password"
            name="confirmPassword"
            prepend-inner-icon="mdi-lock"
            :label="$vuetify.lang.t('$vuetify.labels.confirmPassword')"
            :error-messages="errors"
          ></VAppTextField>
        </ValidationProvider>
      </v-col>
      <v-col cols="12" v-if="showAdmin && loggedUser.admin">
        <v-checkbox
          v-model="userObj.admin"
          :true-value="1"
          :false-value="0"
          :label="$vuetify.lang.t('$vuetify.user.fields.admin')"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col class="py-0" cols="12" v-if="showTerms">
        <ValidationProvider :name="$vuetify.lang.t('$vuetify.user.termsAndConditions')" rules="checkbox" v-slot="{ errors }">
          <v-checkbox v-model="termsSigned" :error-messages="errors">
            <template v-slot:label>
              <div>
                {{ $vuetify.lang.t("$vuetify.user.termsAndConditionsAccept") }}
                <a href="javascript:void(0)" @click.stop @click="dialogTerms = true">
                  {{ $vuetify.lang.t("$vuetify.user.termsAndConditions") }}
                </a>
              </div>
            </template>
          </v-checkbox>
        </ValidationProvider>
        <v-dialog v-model="dialogTerms" max-width="500">
          <v-card>
            <v-card-title class="headline">{{ $vuetify.lang.t("$vuetify.user.termsAndConditions") }}</v-card-title>
            <v-card-text v-html="termsConditionContent"></v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogTerms = false">
                {{ $vuetify.lang.t("$vuetify.buttons.close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </ValidationObserver>
</template>

<script>
import layoutConfigurationApi from "@/api/layoutConfiguration";
import parameterConfigurationApi from "@/api/parameterConfiguration";
import userApi from "@/api/user";
import serviceApi from "@/api/service";
import Mask from "@/mixins/mask";
import tokenService from "@/services/token";
import { enumContentConfiguration } from "@/enums/configurationKeys";
export default {
  name: "FormUser",
  mixins: [Mask],
  data: () => ({
    userObj: {},
    confirmPassword: "",
    dialogTerms: false,
    termsSigned: false,
    termsConditionContent: null,
    loggedUser: null,
    cpfCnpj: null,
    isCnpj: null,
    businessNameLabel: "",
  }),
  props: {
    showTerms: {
      type: Boolean,
      default: false,
    },
    showAdmin: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    var cpfCnpj = localStorage.getItem("cpfCnpj");
    if (cpfCnpj != null) {
      if (cpfCnpj.length == 14) {
        this.userObj.cpf = cpfCnpj;
        this.userObj.businessCnpj = "";
        this.userObj.businessName = "";
        this.isCnpj = false;
        this.userObj.phone = "";
      } else if (cpfCnpj.length == 18) {
        this.userObj.businessCnpj = cpfCnpj;
        this.isCnpj = true;
        this.userObj.phone = "";
      }
    }

    await parameterConfigurationApi.get().then((configurationItems) => {
      configurationItems.forEach((element) => {
        if (element.code === enumContentConfiguration.VUEAPPTITLECLIENT) this.businessNameLabel = element.value;
      });
    });

    if (this.showTerms) {
      // Show Overlay
      this.$emit("control-overlay", true);
      // Get Configuration Colors
      await layoutConfigurationApi
        .get()
        .then((configurationItems) => {
          // Set new colors configuration
          configurationItems.forEach((element) => {
            if (element.code === enumContentConfiguration.TERMSCONDITIONS) this.termsConditionContent = element.value;
          });
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.layoutConfiguration.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Show Overlay
          this.$emit("control-overlay", false);
        });
    }
    if (this.showAdmin) {
      // Get Logged User Data
      this.loggedUser = tokenService.getDecodedToken().user;
      // Check if user exists
      if (!this.loggedUser && !this.loggedUser.id) this.$router.push({ name: "Dashboard" });
    }
  },
  methods: {
    // Login function
    submitForm() {
      // validate form
      this.$refs.observerUser.validate().then((success) => {
        if (success) {
          let apiMethod = this.userObj.id ? "update" : "create";
          // Save User
          userApi[apiMethod](this.userObj)
            .then((userObj) => {
              // Update User Obj
              this.userObj = userObj;
              // Reset Password
              this.confirmPassword = "";
              // Save all servides for user
              if (userObj.admin) {
                // Get Servides to save
                serviceApi
                  .get({
                    size: 999999,
                    sort: "category,asc",
                  })
                  .then((response) => {
                    // Save User permissions
                    userApi
                      .saveUserPermissions(userObj.id, response.data)
                      .then(() => {
                        // Emit Success
                        this.$emit("success", userObj);
                      })
                      .catch((error) => {
                        this.$notify({
                          group: "application",
                          type: "error",
                          title: this.$vuetify.lang.t("$vuetify.api.user.errors.saveUserPermissions"),
                          text: error.message,
                        });
                      });
                  })
                  .catch((error) => {
                    this.$notify({
                      group: "application",
                      type: "error",
                      title: this.$vuetify.lang.t("$vuetify.api.services.errors.get"),
                      text: error.message,
                    });
                  });
              } else {
                // Emit Success
                this.$emit("success", userObj);
              }
            })
            .catch((error) => {
              this.$notify({
                group: "application",
                type: "error",
                title: this.$vuetify.lang.t("$vuetify.api.user.errors." + apiMethod),
                text: error.message,
              });
              // Emit Error
              this.$emit("error");
            });
        } else {
          // Notify Error
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.user.notifications.errors.formObserverUser.title"),
            text: this.$vuetify.lang.t("$vuetify.user.notifications.errors.formObserverUser.message"),
          });
          // Emit Error
          this.$emit("error");
        }
      });
    },
    setUserObj(userObj) {
      this.userObj = userObj;
    },
  },
};
</script>
