<template>
  <v-app-bar app color="primary">
    <router-link :to="{ name: 'Dashboard' }">
      <v-img alt="Techs" class="shrink mr-2" :src="logoPath" transition="scale-transition" height="60px" max-width="200px" contain />
    </router-link>
    <v-spacer></v-spacer>
    <div>
      <v-row justify="end" no-gutters>
        <v-col cols="auto">
          <v-menu offset-y v-if="userToken">
            <template v-slot:activator="{ on }">
              <v-btn dark text v-on="on" iconAfter="mdi-menu-down">
                <v-icon dark>mdi-account</v-icon>{{ userInfo.name }}<v-icon dark>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1 font-weight-bold">{{ userInfo.name }}</v-list-item-title>
                  <v-list-item-title class="text-body-2 font-weight-light">{{ userInfo.businessName }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-subtitle class="font-weight-bold">{{ $vuetify.lang.t("$vuetify.user.lastLogin") }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ userInfo.lastLogin | formatDateTime }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item :to="{ name: 'MyAccount' }">
                <v-list-item-action><v-icon>mdi-account</v-icon></v-list-item-action>
                <v-list-item-title>{{ $vuetify.lang.t("$vuetify.user.myAccount") }}</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'Administrator' }" v-if="userInfo.admin">
                <v-list-item-action><v-icon>mdi-account-group</v-icon></v-list-item-action>
                <v-list-item-title>{{ $vuetify.lang.t("$vuetify.administrator.title") }}</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'Configuration' }" v-if="userInfo.admin">
                <v-list-item-action><v-icon>mdi-cog</v-icon></v-list-item-action>
                <v-list-item-title>{{ $vuetify.lang.t("$vuetify.configuration.titlePl") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleLogoff">
                <v-list-item-action><v-icon>mdi-logout</v-icon></v-list-item-action>
                <v-list-item-title>{{ $vuetify.lang.t("$vuetify.auth.logout") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="auto" class="body-2 white--text">{{ dateNow }}</v-col>
      </v-row>
    </div>
  </v-app-bar>
</template>

<script>
import tokenService from "@/services/token";
import moment from "moment";
import parameterConfiguration from "@/api/parameterConfiguration";
moment.locale("pt-br");
export default {
  name: "AppBar",
  data: () => ({
    userInfo: {},
    loginURL: "",
  }),
  computed: {
    userToken: () => {
      return tokenService.getToken();
    },
    logoPath: () => {
      return process.env.NODE_ENV == "development" ? process.env.VUE_APP_LOGO_PATH : location.origin + "/api/portal-photos/logo.png";
    },
    dateNow: () => {
      return moment().local().format("dddd, DD [de] MMMM [de] YYYY");
    },
  },
  filters: {
    // Update Dates from this component to user's timezone
    formatDateTime: function (value) {
      if (!value) return "";
      return moment(value).local().format("DD/MM/YYYY [às] HH:mm");
    },
  },
  created() {
    if (this.userToken) this.userInfo = tokenService.getDecodedToken().user;
    parameterConfiguration.getOauthpkceURL().then((response) => {
      this.loginURL = response;
    });
  },
  methods: {
    handleLogoff() {
      // Remove Token
      tokenService.removeToken();

      var oauthpkce = localStorage.getItem("oauthpkceParameter");
      if (oauthpkce == "true") {
        var idtoken = localStorage.getItem("id_token");
        window.location.href = `${this.loginURL}/connect/endsession?
id_token_hint=${idtoken}&
post_logout_redirect_uri=${location.origin}`;
      } else {
        // Redirect to Login
        this.$router.push({ name: "Login" });
      }
    },
  },
};
</script>
