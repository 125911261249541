var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observerUser"},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.user.fields.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VAppTextField',{attrs:{"name":"name","prepend-inner-icon":"mdi-account","label":_vm.$vuetify.lang.t('$vuetify.user.fields.name'),"error-messages":errors,"disabled":!!_vm.userObj.id},model:{value:(_vm.userObj.name),callback:function ($$v) {_vm.$set(_vm.userObj, "name", $$v)},expression:"userObj.name"}})]}}])})],1),(!_vm.isCnpj || _vm.isCnpj == null)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.user.fields.cpf'),"rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VAppTextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.cpfMask),expression:"cpfMask"}],attrs:{"name":"cpf","prepend-inner-icon":"mdi-card-account-details","label":_vm.$vuetify.lang.t('$vuetify.user.fields.cpf'),"error-messages":errors,"disabled":_vm.isCnpj != null},model:{value:(_vm.userObj.cpf),callback:function ($$v) {_vm.$set(_vm.userObj, "cpf", $$v)},expression:"userObj.cpf"}})]}}],null,false,4012676671)})],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":_vm.isCnpj || _vm.isCnpj == null ? '6' : '12'}},[_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.user.fields.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VAppTextField',{attrs:{"name":"email","prepend-inner-icon":"mdi-at","label":_vm.$vuetify.lang.t('$vuetify.user.fields.email'),"error-messages":errors,"disabled":!!_vm.userObj.id},model:{value:(_vm.userObj.email),callback:function ($$v) {_vm.$set(_vm.userObj, "email", $$v)},expression:"userObj.email"}})]}}])})],1),(_vm.isCnpj == null)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.user.fields.phone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VAppTextField',{attrs:{"name":"phone","prepend-inner-icon":"mdi-phone","label":_vm.$vuetify.lang.t('$vuetify.user.fields.phone'),"error-messages":errors},model:{value:(_vm.userObj.phone),callback:function ($$v) {_vm.$set(_vm.userObj, "phone", $$v)},expression:"userObj.phone"}})]}}],null,false,348998408)})],1):_vm._e(),(_vm.isCnpj || _vm.isCnpj == null)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":'Nome do ' + _vm.businessNameLabel,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VAppTextField',{attrs:{"name":"businessName","prepend-inner-icon":"mdi-office-building","label":'Nome do ' + _vm.businessNameLabel,"error-messages":errors},model:{value:(_vm.userObj.businessName),callback:function ($$v) {_vm.$set(_vm.userObj, "businessName", $$v)},expression:"userObj.businessName"}})]}}],null,false,131347010)})],1):_vm._e(),(_vm.isCnpj || _vm.isCnpj == null)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":'CNPJ do ' + _vm.businessNameLabel,"rules":"required|cnpj"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VAppTextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.cnpjMask),expression:"cnpjMask"}],attrs:{"name":"businessCnpj","prepend-inner-icon":"mdi-office-building","label":'CNPJ do ' + _vm.businessNameLabel,"error-messages":errors},model:{value:(_vm.userObj.businessCnpj),callback:function ($$v) {_vm.$set(_vm.userObj, "businessCnpj", $$v)},expression:"userObj.businessCnpj"}})]}}],null,false,3799793381)})],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.user.fields.password'),"rules":(_vm.userObj.id ? '' : 'required|') + 'min:8|password'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VAppTextField',{attrs:{"type":"password","name":"password","prepend-inner-icon":"mdi-lock","label":_vm.$vuetify.lang.t('$vuetify.user.fields.password'),"error-messages":errors},model:{value:(_vm.userObj.password),callback:function ($$v) {_vm.$set(_vm.userObj, "password", $$v)},expression:"userObj.password"}})]}}])})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.labels.confirmPassword'),"rules":(_vm.userObj.id ? '' : 'required|') + 'equal:@' + _vm.$vuetify.lang.t('$vuetify.user.fields.password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VAppTextField',{attrs:{"type":"password","name":"confirmPassword","prepend-inner-icon":"mdi-lock","label":_vm.$vuetify.lang.t('$vuetify.labels.confirmPassword'),"error-messages":errors},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}])})],1),(_vm.showAdmin && _vm.loggedUser.admin)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"true-value":1,"false-value":0,"label":_vm.$vuetify.lang.t('$vuetify.user.fields.admin'),"hide-details":""},model:{value:(_vm.userObj.admin),callback:function ($$v) {_vm.$set(_vm.userObj, "admin", $$v)},expression:"userObj.admin"}})],1):_vm._e(),(_vm.showTerms)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.user.termsAndConditions'),"rules":"checkbox"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.user.termsAndConditionsAccept"))+" "),_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":[function($event){$event.stopPropagation();},function($event){_vm.dialogTerms = true}]}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.user.termsAndConditions"))+" ")])])]},proxy:true}],null,true),model:{value:(_vm.termsSigned),callback:function ($$v) {_vm.termsSigned=$$v},expression:"termsSigned"}})]}}],null,false,1183035142)}),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialogTerms),callback:function ($$v) {_vm.dialogTerms=$$v},expression:"dialogTerms"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.user.termsAndConditions")))]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.termsConditionContent)}}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogTerms = false}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.buttons.close"))+" ")])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }